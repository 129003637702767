import React from 'react';
import { Users, Award, Briefcase, Book, Twitter, Linkedin, Github, Instagram } from 'lucide-react';

const AboutPage = () => {
  const values = [
    {
      icon: Users,
      title: 'User Empowerment',
      description: 'We believe in empowering users to achieve more, by letting them focus on the work that truly matters.'
    },
    {
      icon: Award,
      title: 'Responsible Innovation',
      description: 'We are committed to developing AI responsibly, prioritizing transparency, privacy, and security.'
    },
    {
      icon: Briefcase,
      title: 'Transforming Work',
      description: 'Our mission is to revolutionize the way people interact with technology in their daily work, to make work more human.'
    },
    {
      icon: Book,
      title: 'Continuous Learning',
      description: 'We embrace a culture of continuous learning, staying at the forefront of AI research and development.'
    },
  ];

  return (
    <div className="bg-soft-parchment py-16">
      <div className="container mx-auto px-4">
        <h1 className="font-montserrat text-4xl font-bold mb-8 text-center">About Novus Opus</h1>
        
        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Our Story</h2>
          <p className="font-merriweather text-lg mb-4">
            Novus Opus was founded in 2022 by a team of AI researchers, software engineers, and interaction designers passionate about pushing the boundaries of human-computer interaction. We saw an opportunity to harness the power of AI to transform how people work with computers, making automation more accessible and intuitive.
          </p>
          <p className="font-merriweather text-lg">
            Our journey began with the development of Otto, an AI-powered computer assistant that combines high-level planning with precise, low-level control. By seamlessly integrating with existing applications and workflows, Ottoenables users to automate tasks faster and more efficiently than ever before.
          </p>
        </div>

        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Our Mission</h2>
          <p className="font-merriweather text-lg">
            At Novus Opus, our mission is to automate work responsibly through AI. We strive to create AI-powered tools that empower professionals to focus on high-value, creative work while leaving routine tasks to intelligent automation. We are committed to developing AI that is transparent, secure, and aligned with human values.
          </p>
        </div>

        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div key={index} className="bg-white shadow-md p-6 rounded-lg">
                <value.icon className="text-vibrant-coral mb-4" size={48} />
                <h3 className="font-montserrat text-xl font-bold mb-2">{value.title}</h3>
                <p className="font-merriweather">{value.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* <div>
          <h2 className="font-montserrat text-3xl font-bold mb-4">Meet the Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white shadow-md p-6 rounded-lg">
                <img src="https://placehold.co/600x400" alt="Team Member" className="w-full h-auto rounded-lg mb-4" />
                <h3 className="font-montserrat text-xl font-bold mb-2">John Doe</h3>
                <p className="font-merriweather">CEO</p>
                <p className="font-merriweather">John is a seasoned AI researcher with a passion for pushing the boundaries of human-computer interaction.</p>
                <div className="flex space-x-4 mt-4">
                    <a href="https://twitter.com/johndoe" className="hover:text-vibrant-coral transition-colors"><Twitter size={20} /></a>
                    <a href="https://linkedin.com/in/johndoe" className="hover:text-vibrant-coral transition-colors"><Linkedin size={20} /></a>
                    <a href="https://github.com/johndoe" className="hover:text-vibrant-coral transition-colors"><Github size={20} /></a>    
                    <a href="https://www.instagram.com/johndoe" className="hover:text-vibrant-coral transition-colors"><Instagram size={20} /></a>
                </div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default AboutPage;