import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-soft-parchment py-16">
      <div className="container mx-auto px-4">
        <h1 className="font-montserrat text-4xl font-bold mb-8">Privacy Policy</h1>
        <p className="font-merriweather text-lg mb-8">
          At Novus Opus, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website and services, including Otto.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Information We Collect</h2>
        <p className="font-merriweather text-lg mb-8">
          We collect information you provide directly to us, such as when you create an account, fill out a form, or communicate with us. We also collect information automatically when you use our services, including device information, log files, and usage data.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">How We Use Your Information</h2>
        <p className="font-merriweather text-lg mb-8">
          We use your information to provide, maintain, and improve our services, communicate with you, and personalize your experience. We may also use your information for research and development purposes to improve our AI technology and ensure responsible AI development.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Information Sharing and Disclosure</h2>
        <p className="font-merriweather text-lg mb-8">
          We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our website and services. We may also disclose your information if required by law or to protect our rights and property.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Data Security</h2>
        <p className="font-merriweather text-lg mb-8">
          We implement reasonable security measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Your Choices</h2>
        <p className="font-merriweather text-lg mb-8">
          You can update your account information and communication preferences at any time. You may also opt-out of receiving promotional emails from us by following the unsubscribe instructions in those emails.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Changes to This Privacy Policy</h2>
        <p className="font-merriweather text-lg mb-8">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of the policy.
        </p>

        <h2 className="font-montserrat text-2xl font-bold mb-4">Contact Us</h2>
        <p className="font-merriweather text-lg">
          If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:privacy@novusopus.com" className="text-vibrant-coral hover:text-deep-indigo">privacy@novusopus.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;