import React from 'react';
import { Code, Zap, ShieldCheck, Cpu } from 'lucide-react';

const OttoOverview = () => {
  const features = [
    {
      icon: Code,
      title: 'Seamless Automation',
      description: 'Ottoseamlessly automates tasks across all your applications, adapting to your unique workflows.'
    },
    {
      icon: Zap,
      title: 'Lightning-Fast Performance',
      description: 'With Otto, tasks are completed faster than humanly possible, without errors or fatigue.'
    },
    {
      icon: ShieldCheck,
      title: 'Responsible AI',
      description: 'We are committed to developing AI that empowers users while adhering to ethical principles.'
    },
    {
      icon: Cpu,
      title: 'Advanced Technology',
      description: 'Ottocombines cutting-edge AI planning and continuous control to revolutionize computer interaction.'
    },
  ];

  return (
    <div className="bg-soft-parchment py-16">
      <div className="container mx-auto px-4">
        <h1 className="font-montserrat text-4xl font-bold mb-8 text-center">Introducing <b>Otto</b></h1>
        <p className="font-merriweather text-xl mb-12 text-center">
          The AI-powered computer assistant that automates work responsibly.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
          <div>
            <h2 className="font-montserrat text-3xl font-bold mb-4">AI Planning Meets Continuous Control</h2>
            <p className="font-merriweather text-lg">
              Ottois the first AI assistant that combines high-level planning with precise, low-level control. It understands your goals and autonomously performs the necessary actions, interacting with applications just like a human would - but faster and more efficiently.
            </p>
          </div>
          <div>
            {/* Replace with an actual product screenshot or illustration */}
            <img src="/path/to/product-screenshot.png" alt="Ottoproduct screenshot" />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white shadow-md p-6 rounded-lg">
              <feature.icon className="text-vibrant-coral mb-4" size={48} />
              <h3 className="font-montserrat text-xl font-bold mb-2">{feature.title}</h3>
              <p className="font-merriweather">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OttoOverview;