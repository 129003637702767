import React, { useState, useRef } from 'react';
import { Code, Zap, Shield, ArrowRight, ChevronDown, ChevronUp, Mail, PlayCircle, X, Monitor, Mouse, Brain, MessageSquare, Eye, Cpu,  Lock, Sliders, Users, Menu } from 'lucide-react';
import Logo from './Logo';
import PricingSection from './PricingSection';
import Footer from './Footer';

const LandingPage = () => {
    const [openFaq, setOpenFaq] = useState(null);
    const [showTeaser, setShowTeaser] = useState(false);
    const navRef = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const [showTechDetails, setShowTechDetails] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

  const Navigation = () => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section && navRef.current) {
        const navHeight = navRef.current.offsetHeight;
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: sectionPosition - navHeight,
          behavior: 'smooth'
        });
      }
      setIsMenuOpen(false);
    };

    return (
      <nav ref={navRef} className={`bg-white p-4 shadow-md sticky top-0 z-50 transition-all duration-300`}>
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Logo width="40" height="40" fill="#2D1A54" />
            <div className="text-2xl font-bold ml-2">
              <span className="font-montserrat font-bold">NOVUS</span> <span className="font-merriweather font-light">OPUS</span>
            </div>
          </div>
          <div className="hidden md:flex space-x-4">
            <button onClick={() => scrollToSection('value-proposition')} className="text-deep-indigo hover:text-vibrant-coral transition-colors">Features</button>
            <button onClick={() => scrollToSection('how-it-works')} className="text-deep-indigo hover:text-vibrant-coral transition-colors">How It Works</button>
            <button onClick={() => scrollToSection('pricing')} className="text-deep-indigo hover:text-vibrant-coral transition-colors">Pricing</button>
            <button onClick={() => scrollToSection('faq')} className="text-deep-indigo hover:text-vibrant-coral transition-colors">FAQ</button>
            <button className="bg-vibrant-coral text-white px-6 py-3 rounded-lg hover:bg-dark-sand transition-colors text-lg">Get Started</button>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-deep-indigo">
              <Menu size={24} />
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden mt-4">
            <button onClick={() => scrollToSection('value-proposition')} className="block w-full text-left py-2 text-deep-indigo hover:text-vibrant-coral transition-colors">Features</button>
            <button onClick={() => scrollToSection('how-it-works')} className="block w-full text-left py-2 text-deep-indigo hover:text-vibrant-coral transition-colors">How It Works</button>
            <button onClick={() => scrollToSection('pricing')} className="block w-full text-left py-2 text-deep-indigo hover:text-vibrant-coral transition-colors">Pricing</button>
            <button onClick={() => scrollToSection('faq')} className="block w-full text-left py-2 text-deep-indigo hover:text-vibrant-coral transition-colors">FAQ</button>
            <button className="w-full bg-vibrant-coral text-white px-6 py-3 rounded-lg hover:bg-dark-sand transition-colors text-lg mt-2">Get Started</button>
          </div>
        )}
      </nav>
    );
  };

  const HeroSection = () => (
    <header className="relative bg-deep-indigo text-white py-24 overflow-hidden">
      <div className="absolute inset-0 opacity-20">
        <div className="w-full h-full bg-gradient-to-br from-mint-green to-warm-sand"></div>
      </div>
      <div className="container mx-auto text-center relative z-10">
        <h1 className="font-montserrat text-6xl font-bold mb-6 animate-fade-in">Focus on what matters, <br /> Automate the rest</h1>
        <p className="font-merriweather text-2xl mb-10 animate-fade-in-delay max-w-3xl mx-auto">Introducing <b>Otto</b> by Novus Opus</p>
        <div className="flex justify-center space-x-4">
          <button className="bg-vibrant-coral text-white px-8 py-4 rounded-lg text-xl hover:bg-dark-sand transition-colors">
            Join Waitlist <ArrowRight className="inline ml-2" />
          </button>
          <button onClick={() => setShowTeaser(true)} className="bg-transparent border-2 border-white text-white px-8 py-4 rounded-lg text-xl hover:bg-white hover:text-deep-indigo transition-colors">
            Watch Teaser <PlayCircle className="inline ml-2" />
          </button>
        </div>
      </div>
    </header>
  );

  const TeaserVideo = () => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${showTeaser ? '' : 'hidden'}`}>
      <div className="bg-white p-4 rounded-lg relative">
        <button onClick={() => setShowTeaser(false)} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
          <X size={24} />
        </button>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="Otto by Novus Opus Teaser"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );

  const ValuePropositionSection = () => (
    <section id="value-proposition" className="bg-white py-16">
      <div className="container mx-auto">
        <h2 className="font-montserrat text-3xl font-bold text-center mb-8">Seamless Fusion of AI Planning and Continuous Control</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-soft-parchment p-6 rounded-lg shadow-md">
            <h3 className="font-montserrat text-xl font-bold mb-4">Human-Like Interaction</h3>
            <p className="font-merriweather">Otto mimics human cognitive processes, allowing for intuitive automation across any software interface.</p>
          </div>
          <div className="bg-soft-parchment p-6 rounded-lg shadow-md">
            <h3 className="font-montserrat text-xl font-bold mb-4">Universal Applicability</h3>
            <p className="font-merriweather">From simple tasks to complex workflows, Otto adapts to your needs across all computer-based applications.</p>
          </div>
        </div>
      </div>
    </section>
  );

  const KeyDifferentiators = () => (
    <section className="bg-warm-sand py-16">
      <div className="container mx-auto">
        <h2 className="font-montserrat text-3xl font-bold text-center mb-8">What Sets Us Apart</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { icon: Code, title: "Universal Automation", desc: "Seamlessly automate tasks across all applications" },
            { icon: Zap, title: "Balanced Control", desc: "Powerful automation with user empowerment" },
            { icon: Shield, title: "Ethical Innovation", desc: "Committed to responsible AI development and deployment" }
          ].map((diff, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow transform hover:-translate-y-1">
              <diff.icon size={48} className="text-deep-indigo mx-auto mb-4" />
              <h3 className="font-montserrat text-xl font-bold mb-2">{diff.title}</h3>
              <p className="font-merriweather">{diff.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );

  const HowItWorks = () => {
    const steps = [
      {
        icon: Monitor,
        title: "Screen Capture",
        description: "Otto securely streams your display, capturing the digital workspace in real-time.",
        example: "E.g., Capturing a complex spreadsheet or a multi-window development environment."
      },
      {
        icon: Eye,
        title: "Visual Understanding",
        description: "Our AI analyzes the screen content, recognizing UI elements, text, and context.",
        example: "E.g., Identifying input fields, buttons, and data structures in your applications."
      },
      {
        icon: Brain,
        title: "Task Planning",
        description: "The AI plans a series of actions to accomplish your specified task, breaking it into manageable steps.",
        example: "E.g., Planning how to extract data from multiple sources and compile it into a report."
      },
      {
        icon: Mouse,
        title: "Precise Execution",
        description: "Otto controls your mouse and keyboard, executing actions with human-like precision.",
        example: "E.g., Navigating through menus, entering data, and triggering actions across different applications."
      },
      {
        icon: MessageSquare,
        title: "Interactive Collaboration",
        description: "The AI can pause to ask for clarification, and you can interrupt or provide additional context at any time.",
        example: "E.g., Asking for confirmation before sending an email or clarifying ambiguous data entries."
      },
      {
        icon: Shield,
        title: "Safety and Review",
        description: "Built-in safeguards prevent unintended actions, and you can review the AI's decision-making process.",
        example: "E.g., Confirming before deleting important files or modifying critical settings."
      }
    ];

    return (
      <section id="how-it-works" className="bg-white py-16">
        <div className="container mx-auto">
          <h2 className="font-montserrat text-4xl font-bold text-center mb-12">How Otto Works</h2>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-8">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg shadow-md transition-all duration-300 cursor-pointer ${activeStep === index ? 'bg-vibrant-coral text-white' : 'bg-soft-parchment'}`}
                  onClick={() => setActiveStep(index)}
                >
                  <div className="flex items-center mb-4">
                    <step.icon className={`mr-4 ${activeStep === index ? 'text-white' : 'text-deep-indigo'}`} size={24} />
                    <h3 className="font-montserrat text-xl font-bold">{step.title}</h3>
                  </div>
                  <p className="font-merriweather mb-2">{step.description}</p>
                  <p className="font-merriweather text-sm italic">{step.example}</p>
                </div>
              ))}
            </div>

            <div className="bg-warm-sand p-8 rounded-lg shadow-lg">
              <h3 className="font-montserrat text-2xl font-bold mb-6">See Otto in Action</h3>
              <div className="bg-white p-4 rounded-md shadow-inner mb-6">
                <p className="font-merriweather mb-4">Watch as Otto automates a complex task:</p>
                <ol className="list-decimal list-inside space-y-2 font-merriweather">
                  <li>Extracts data from multiple spreadsheets</li>
                  <li>Compiles information into a structured report</li>
                  <li>Generates visualizations based on the data</li>
                  <li>Sends the report to specified recipients</li>
                </ol>
              </div>
              <button className="bg-vibrant-coral text-white px-6 py-3 rounded-lg text-lg hover:bg-dark-sand transition-colors font-montserrat flex items-center justify-center w-full">
                Watch Demo <PlayCircle className="ml-2" />
              </button>
            </div>
          </div>

          <div className="mt-12">
            <button 
              className="bg-deep-indigo text-white px-6 py-3 rounded-lg text-lg hover:bg-vibrant-coral transition-colors font-montserrat flex items-center justify-center mx-auto"
              onClick={() => setShowTechDetails(!showTechDetails)}
            >
              {showTechDetails ? 'Hide' : 'Show'} Technical Details <ChevronDown className={`ml-2 transition-transform duration-300 ${showTechDetails ? 'transform rotate-180' : ''}`} />
            </button>
            
            {showTechDetails && (
              <div className="mt-6 bg-soft-parchment p-6 rounded-lg shadow-md">
                <h3 className="font-montserrat text-xl font-bold mb-4">Technical Highlights</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-montserrat text-lg font-semibold mb-2 flex items-center">
                      <Cpu className="mr-2" /> AI Architecture
                    </h4>
                    <ul className="list-disc list-inside space-y-1 font-merriweather">
                      <li>Advanced computer vision models for screen analysis</li>
                      <li>Natural language processing for understanding context and instructions</li>
                      <li>Reinforcement learning for adaptive task planning</li>
                      <li>Fine-tuned large language models for decision-making and interaction</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-montserrat text-lg font-semibold mb-2 flex items-center">
                      <Lock className="mr-2" /> Security & Privacy
                    </h4>
                    <ul className="list-disc list-inside space-y-1 font-merriweather">
                      <li>End-to-end encryption for all data transmissions</li>
                      <li>On-premise deployment options for sensitive environments</li>
                      <li>Granular access controls and audit logging</li>
                      <li>Compliance with GDPR, CCPA, and other data protection regulations</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-montserrat text-lg font-semibold mb-2 flex items-center">
                      <Sliders className="mr-2" /> Customization & Integration
                    </h4>
                    <ul className="list-disc list-inside space-y-1 font-merriweather">
                      <li>API access for seamless integration with existing workflows</li>
                      <li>Custom model training for industry-specific tasks</li>
                      <li>Scalable architecture to handle enterprise-level workloads</li>
                      <li>Support for multi-language and multi-platform environments</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-montserrat text-lg font-semibold mb-2 flex items-center">
                      <Users className="mr-2" /> Collaboration & Control
                    </h4>
                    <ul className="list-disc list-inside space-y-1 font-merriweather">
                      <li>Real-time collaboration features for team workflows</li>
                      <li>Customizable approval processes for critical actions</li>
                      <li>Detailed activity logs and performance analytics</li>
                      <li>User-friendly interface for managing AI behaviors and preferences</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  };

  const EthicalAICommitment = () => (
    <section className="bg-white py-16">
      <div className="container mx-auto">
        <h2 className="font-montserrat text-3xl font-bold text-center mb-8">Our Commitment to Ethical AI</h2>
        <div className="bg-soft-parchment p-8 rounded-lg shadow-md">
          <h3 className="font-montserrat text-2xl font-bold mb-4">Responsible Innovation</h3>
          <p className="font-merriweather mb-4">At Novus Opus, we believe in the power of AI to transform work, but not at the cost of ethical considerations. Our development process for Otto is guided by:</p>
          <ul className="list-disc list-inside mb-4 font-merriweather">
            <li>Transparency in AI decision-making</li>
            <li>User privacy and data protection</li>
            <li>Fairness and bias mitigation in automation</li>
            <li>Continuous monitoring and improvement of AI systems</li>
          </ul>
          <p className="font-merriweather">We're committed to shaping a future where AI empowers humans, not replaces them.</p>
        </div>
      </div>
    </section>
  );




  const FAQ = () => (
    <section id="faq" className="bg-white py-16">
      <div className="container mx-auto">
        <h2 className="font-montserrat text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
        {[
          { q: "How does Otto ensure data security?", a: "We employ end-to-end encryption and adhere to strict data protection protocols. Your data never leaves your systems." },
          { q: "Can Otto integrate with my existing tools?", a: "Yes, Otto is designed to work with any computer-based application, ensuring seamless integration with your current tech stack." },
          { q: "What kind of support do you offer?", a: "We provide 24/7 technical support, regular training sessions, and a comprehensive knowledge base to ensure you get the most out of Otto." }
        ].map((faq, index) => (
          <div key={index} className="mb-4 border-b border-gray-200 pb-4">
            <button
              className="flex justify-between items-center w-full text-left font-bold font-montserrat"
              onClick={() => setOpenFaq(openFaq === index ? null : index)}
            >
              {faq.q}
              {openFaq === index ? <ChevronUp /> : <ChevronDown />}
            </button>
            {openFaq === index && <p className="mt-2 font-merriweather">{faq.a}</p>}
          </div>
        ))}
      </div>
    </section>
  );

  const NewsletterSignup = () => (
    <section className="bg-warm-sand py-16">
      <div className="container mx-auto text-center">
        <h2 className="font-montserrat text-3xl font-bold mb-4">Stay Updated</h2>
        <p className="mb-8 font-merriweather">Subscribe to our newsletter for the latest updates on Otto and research at Novus Opus.</p>
        <form className="max-w-md mx-auto flex">
          <input
            type="email"
            placeholder="Enter your email"
            className="flex-grow p-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-deep-indigo font-montserrat"
          />
          <button type="submit" className="bg-vibrant-coral text-white px-4 py-2 rounded-r-lg hover:bg-dark-sand transition-colors">
            <Mail className="inline" />
          </button>
        </form>
      </div>
    </section>
  );




  return (
    <div className="bg-soft-parchment min-h-screen font-montserrat text-deep-indigo">
      <Navigation />
      <HeroSection />
      <TeaserVideo />
      <ValuePropositionSection />
      <KeyDifferentiators />
      <HowItWorks />
      <EthicalAICommitment />
      <PricingSection />
      <FAQ />
      <NewsletterSignup />
      <Footer />
    </div>
  );
};

export default LandingPage;