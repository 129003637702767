import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import the pages you want to route to
import LandingPage from './LandingPage';
import AboutPage from './pages/AboutPage';
import MissionPage from './pages/MissionPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import OttoOverviewPage from './pages/OttoOverviewPage';
import PricingPage from './pages/PricingPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define all your routes here */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/mission" element={<MissionPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/product/overview" element={<OttoOverviewPage />} />
        {/* <Route path="/pricing" element={<PricingPage />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
