import React from 'react';
import { Check, ArrowRight } from 'lucide-react';

const PricingSection = () => {
  const tiers = [
    {
      name: "Free",
      price: "$0",
      description: "For individuals just getting started with AI automation",
      features: [
        "50 automated tasks per month",
        "Core AI automation capabilities",
        "Community support",
        "3-day task history",
      ],
      cta: "Get Started",
      highlighted: false,
    },
    {
      name: "Pro",
      price: "$59",
      period: "per month",
      description: "For power users and small teams",
      features: [
        "Unlimited automated tasks",
        "Priority processing",
        "All AI automation capabilities",
        "Email support",
        "30-day task history",
        "Early access to new features",
      ],
      cta: "Upgrade to Pro",
      highlighted: true,
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "For large organizations with complex needs",
      features: [
        "All Pro features",
        "Dedicated account manager",
        "Custom AI model training",
        "On-premise deployment options",
        "Advanced security features",
        "24/7 premium support",
      ],
      cta: "Contact Sales",
      highlighted: false,
    },
  ];

  return (
    <section id="pricing" className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Simple, Transparent Pricing</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {tiers.map((tier, index) => (
            <div
              key={index}
              className={`border rounded-lg p-8 flex flex-col ${
                tier.highlighted
                  ? 'border-vibrant-coral shadow-lg'
                  : 'border-gray-200'
              }`}
            >
              <h3 className="text-2xl font-bold mb-4">{tier.name}</h3>
              <p className="text-4xl font-bold mb-4">
                {tier.price}
                {tier.period && <span className="text-xl font-normal">/{tier.period}</span>}
              </p>
              <p className="text-gray-600 mb-6">{tier.description}</p>
              <ul className="mb-8 flex-grow">
                {tier.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center mb-3">
                    <Check className="text-green-500 mr-2" size={20} />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button
                className={`w-full py-2 px-4 rounded font-bold mt-auto ${
                  tier.highlighted
                    ? 'bg-vibrant-coral text-white hover:bg-vibrant-coral-dark'
                    : 'bg-deep-indigo text-white hover:bg-deep-indigo-dark'
                } transition-colors duration-300`}
              >
                {tier.cta}
                <ArrowRight className="inline-block ml-2" size={20} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;