import React from 'react';

const Logo = (props) => (
  <svg
    width={props.width || "300"}
    height={props.height || "176"}
    viewBox="0 0 3000 1760"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0.000000,1760.000000) scale(1,-1)">
      <path
        d="M730 1609 c-170 -33 -353 -203 -473 -439 -99 -196 -149 -469 -118
-636 37 -194 158 -314 317 -314 184 0 483 117 958 373 130 70 150 78 158 63
27 -47 141 -190 203 -251 130 -131 256 -188 415 -188 161 0 301 64 441 202
306 303 380 764 139 864 -122 51 -284 19 -572 -116 -86 -40 -446 -231 -555
-294 l-42 -24 -145 218 c-79 120 -174 255 -211 301 -165 204 -324 278 -515
241z m225 -158 c55 -25 134 -99 209 -195 101 -129 319 -467 308 -478 -15 -15
-273 -152 -437 -233 -254 -124 -455 -195 -558 -195 -55 0 -105 24 -118 57 -29
72 -7 242 56 430 97 290 265 546 400 609 60 28 87 29 140 5z m1704 -287 c22
-16 23 -22 18 -78 -22 -230 -258 -596 -443 -687 -128 -63 -229 -36 -360 95
-87 88 -199 235 -188 246 16 15 288 163 479 260 236 121 369 173 455 179 9 1
26 -6 39 -15z"
        fill={props.fill || "#000"}
      />
    </g>
  </svg>
);

export default Logo;