import React from 'react';
import { Twitter, Linkedin, Github, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const footerSections = [
    {
      title: "Product",
      links: [
        { name: "Overview", href: "/product/overview" },
        { name: "For Individuals", href: "/product/individuals" },
        { name: "For Teams", href: "/product/teams" },
        { name: "For Enterprises", href: "/product/enterprises" },
        // { name: "Pricing", href: "/pricing" },
        { name: "Demo", href: "/demo" },
      ]
    },
    {
      title: "Company",
      links: [
        { name: "About Us", href: "/about" },
        { name: "Our Mission", href: "/mission" },
        { name: "Careers", href: "/careers" },
        { name: "Contact", href: "/contact" },
      ]
    },
    {
      title: "Resources",
      links: [
        // { name: "Documentation", href: "/docs" },
        // { name: "API", href: "/api" },
        { name: "Blog", href: "/blog" },
        { name: "Case Studies", href: "/case-studies" },
      ]
    },
    {
      title: "Legal",
      links: [
        { name: "Privacy Policy", href: "/privacy-policy"  },
        { name: "Terms of Service", href: "/terms" },
        { name: "Security", href: "/security" },
      ]
    },
  ];

  return (
    <footer className="bg-deep-indigo text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-8">
          {footerSections.map((section, index) => (
            <div key={index}>
              <h3 className="font-bold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link to={link.href} className="hover:text-vibrant-coral transition-colors">
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p>Novus Opus © {currentYear}</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="https://twitter.com/novusopus" className="hover:text-vibrant-coral transition-colors"><Twitter size={20} /></a>
            <a href="https://www.linkedin.com/company/novus-opus" className="hover:text-vibrant-coral transition-colors"><Linkedin size={20} /></a>
            {/* <a href="https://github.com/novusopus" className="hover:text-vibrant-coral transition-colors"><Github size={20} /></a> */}
            {/* <a href="https://www.instagram.com/novusopus" className="hover:text-vibrant-coral transition-colors"><Instagram size={20} /></a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;