import React from 'react';

const MissionPage = () => {
  return (
    <div className="bg-soft-parchment py-16">
      <div className="container mx-auto px-4">
        <h1 className="font-montserrat text-4xl font-bold mb-8 text-center">Our Mission</h1>
        
        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Automating Work Responsibly Through AI</h2>
          <p className="font-merriweather text-lg mb-4">
            At Novus Opus, our mission is to revolutionize the way people work by harnessing the power of artificial intelligence. We believe that AI has the potential to transform industries and empower professionals to focus on high-value, creative tasks while leaving routine work to intelligent automation.
          </p>
          <p className="font-merriweather text-lg">
            However, we also recognize the importance of developing AI responsibly. We are committed to creating AI-powered tools that are transparent, secure, and aligned with human values. Our goal is to foster a future where AI and humans work together seamlessly, enhancing productivity and innovation while maintaining the highest ethical standards.
          </p>
        </div>

        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Empowering Professionals Through Intuitive Automation</h2>
          <p className="font-merriweather text-lg mb-4">
            Our flagship product, Otto, embodies our mission to empower professionals through intuitive automation. By combining advanced AI planning with precise, low-level control, Ottoseamlessly integrates with existing applications and workflows, enabling users to automate tasks faster and more efficiently than ever before.
          </p>
          <p className="font-merriweather text-lg">
            With Otto, we aim to make AI-powered automation accessible to professionals across industries, from software development and data analysis to creative work and administrative tasks. By reducing the time and effort spent on repetitive tasks, we enable users to focus on the work that truly matters – the work that requires human creativity, empathy, and strategic thinking.
          </p>
        </div>

        <div className="mb-12">
          <h2 className="font-montserrat text-3xl font-bold mb-4">Pioneering Responsible AI Development</h2>
          <p className="font-merriweather text-lg mb-4">
            As an AI technology company, we have a responsibility to ensure that our innovations benefit society as a whole. We are committed to pioneering responsible AI development practices that prioritize transparency, privacy, security, and fairness.
          </p>
          <p className="font-merriweather text-lg">
            Our research interests include not only advancing the state-of-the-art in AI planning and continuous control but also exploring the ethical implications of AI deployment and developing best practices for human-AI collaboration. We actively engage with the AI ethics community and strive to be a leader in shaping the future of responsible AI innovation.
          </p>
        </div>

        <div>
          <h2 className="font-montserrat text-3xl font-bold mb-4">Join Us in Shaping the Future of Work</h2>
          <p className="font-merriweather text-lg mb-4">
            At Novus Opus, we are excited about the potential of AI to transform the way we work and live. We invite you to join us on this journey of responsible innovation and help shape a future where AI empowers professionals to achieve more than ever before.
          </p>
          <p className="font-merriweather text-lg">
            Whether you are a user of our products, a partner in our ecosystem, or a member of our team, you have a role to play in realizing our mission. Together, we can build a future of work that is more efficient, more fulfilling, and more human-centered.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionPage;